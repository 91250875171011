/* RESET*/
html {
  box-sizing: border-box;
  font-size: 16px;
  /* background-color: rgb(213, 209, 233); */
  background-color: rgb(213, 207, 230);
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol,
ul {
  list-style: none;
}
h1,
h2 {
  margin: 0px auto 10px auto;
  text-align: center;
}
img {
  max-width: 100%;
  height: auto;
}
.App {
  margin-top: 100px;
  text-align: center;
}
body {
  font-family: "Atma", cursive;
  position: relative;
  background-image: url("./assets/background.svg");
}

a {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  transition: 0.5s;
  text-decoration: none;
  display: block;
}
a:hover {
  text-decoration: underline;
}

.homepage-column {
  flex: 1;
  min-width: 250px;
  text-align: justify;
  background-color: rgb(213, 209, 233);
  border: 1px solid #aeabab;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.homepage-column p {
  margin: 10px;
}
p {
  line-height: 22px;
}
.homepage-column h1 {
  text-align: center;
  font-size: 24px;
  margin-bottom: 10px;
}

.homepage-column img {
  width: 100%;
}
footer {
  font-size: 14px;
  margin-top: 15px;
  color: #fff;
  width: 100vw;
  max-width: 100%;
  background-color: #2b2f38;
  display: flex;
  padding: 20px 0;
  flex-wrap: wrap;
}

.footer-column {
  flex: 1;
  min-width: 250px;
}

.footer-column:nth-child(3) {
  align-items: center;
  justify-content: center;
  display: flex;
}

.footer-title {
  margin-bottom: 10px;
}
footer a {
  font-size: 14px;
  display: inline;
}

.footerSocial img {
  margin-left: 30px;
  width: 30px;
  height: 30px;
  filter: invert(100%);
  margin-bottom: 10px;
}

.container {
  display: flex;
  width: 100%;
  gap: 30px;
  flex-wrap: wrap;
  padding: 0 20px;
}

.NotFound img {
  text-align: center;
  width: 25%;
}
.NotFound {
  padding: 100px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dogs-column {
  flex: 1;
  min-width: 250px;
  text-align: center;
  border: 1px solid #2b2f38;
  border-radius: 10px;
  background-color: rgb(213, 209, 233);
}
.dogs-column:hover {
  transition: all 0.3s;
  filter: brightness(85%);
}

.image-container {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
}
.content-container {
  padding: 0 15px;
  text-align: justify;
}
.dogs-column img {
  align-items: center;
  justify-content: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  max-width: 100%;
  transition: all 0.3s;
  display: block;
  transform: scale(1);
  width: 100%;
  height: 30em;
  object-fit: cover;
  cursor: pointer;
}
.dogs-column:hover img {
  transform: scale(1.1);
  filter: brightness(85%);
}

.dogs-column h1 {
  text-align: center;
  font-size: 24px;
  margin-bottom: 10px;
}
.dogs-column p {
  margin-top: 10px;
}
.dogs-container {
  padding-top: 20px;
  display: flex;
}
.dogs-container a {
  display: flex;
  flex: 1;
  min-width: 250px;
  text-decoration: none;
  color: #000000;
  font-size: 16px;
}
.half-width-container {
  margin: 0 auto;
  width: 50%;
  text-align: center;
  padding-top: 20px;
}
.background-image {
  border: 1px solid #2b2f38;
  border-top: none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: rgb(213, 209, 233);
  border: 1px solid #aeabab;
}
.half-width-container li {
  margin-bottom: 10px;
}
.intro {
  text-align: justify;
  width: 100%;
}
.intro p {
  margin-bottom: 15px;
}
.donation-button {
  border-radius: 5px;
  border: none;
  background-color: #2b2f38;
  padding: 10px 20px;
}
.donation-button:hover {
  cursor: pointer;
  filter: brightness(80%);
}
.donation-button a {
  text-decoration: none;
}
.animal-button-container {
  display: flex;
  margin-bottom: 10px;
}
.animal-button {
  padding: 10px 20px;
  margin: 20px auto;
  background-color: #798195;
  border: 1px solid #000000;
  cursor: pointer;
}
.animal-button:hover {
  background-color: #2b2f38;
  border: 1px solid #000000;
  color: #fff;
}
/* ANIMAL BOX COMPONENT STARTS*/
.animal-box-container {
  width: 100%;
  text-align: center;
  overflow: hidden;
  border-radius: 10px;
}
.animal-box {
  position: relative;
  width: 100%;
  display: flex;
  padding: 10px;
  border: 1px solid #2b2f38;
  border-radius: 10px;
  box-shadow: 2px 2px 8px 1px #646d82;
  background-color: rgb(195, 190, 215);
  overflow: hidden;
}
.animal-box a {
  text-decoration: none;
  color: #000000;
  font-size: 16px;
}
.animal-box a:hover {
  text-decoration: underline;
}
.animal-box .content {
  text-align: justify;
  padding-right: 10px;
  min-width: 300px;
}

.animal-box .content p {
  margin-bottom: 10px;
}

.animal-box .sponsor-status {
  width: 200px;
  margin: 10px auto;
  padding: 10px;
  text-align: center;
  border: 1px solid #2b2f38;
  border-radius: 10px;
}

.animal-box .sponsored {
  background-color: #345232;
  color: #fff;
}
.animal-box .looking-for-sponsor {
  color: #fff;
  background-color: #c17676;
}
.justify {
  text-align: justify;
  padding: 0 10px;
  width: 100%;
}
.justify a {
  margin-top: 10px;
  text-align: center;
  text-decoration: none;
  color: #2b2f38;
  font-size: 16px;
}
.justify a:hover {
  text-align: center;
  text-decoration: underline;
  color: #2b2f38;
  font-size: 16px;
}
/* ANIMAL BOX COMPONENT ENDS*/

/* HAPPY TAILS STARTS */
.happy-tails {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: space-around;
}
.happy-tails img {
  width: 50vw;
  height: 20vw;
  object-fit: cover;
}
.happy-tails-card {
  min-width: 250px;
  flex: 1;
  background-color: rgb(213, 207, 230);
  border: 1px solid #2b2f38;
  overflow: hidden;
  border-radius: 10px;
}
.happy-tails-card:hover {
  transition: all 0.3s;
  filter: brightness(85%);
}
.happy-tails-card-story {
  padding: 10px;
  text-align: justify;
}
/* HAPPY TAILS ENDS */

/* CONTACT STARTS*/
.contact-container {
  text-align: justify;
}
.contact-container p {
  margin-bottom: 10px;
}
.contact-container .info {
  margin-top: 10px;
}
.contact-container h1 {
  position: absolute;
  z-index: 1;
  top: 20px;
  left: 0;
  right: 0;
}
.contact-image {
  position: relative;
  margin-bottom: 10px;
}
.contact-image img {
  width: 100%;
  display: block;
  margin: auto;
}

.button-container {
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  gap: 20px;
}
.button-container button {
  flex: 1;
}
/* CONTACT ENDS*/

/* ------ Media queries ------- */
@media (max-width: 800px) {
  .half-width-container {
    width: 100%;
  }
  .animal-box {
    flex-wrap: wrap;
  }
}

@media (max-width: 1550px) {
  .animal-box {
    flex-wrap: wrap;
    overflow: hidden;
    align-items: center;
    justify-content: center;
  }
  .animal-button {
    width: 50%;
  }
}

@media (max-width: 600px) {
  .happy-tails img {
    width: 100vw;
    height: 60vw;
    object-fit: cover;
  }
}

.donationContainer {
  display: flex;
  gap: 40px;
  justify-content: center;
  flex-wrap: wrap;
  padding: 20px;
}

.oneTimeDonationContainer {
  width: 100%;
}

/* HOLDMYPAW STARTS*/
.holdmypaw .imageContainer {
  display: flex;
  gap: 40px;
  justify-content: center;
  flex-wrap: wrap;
  padding: 20px;
}
.holdmypaw .imageContainer img {
  object-fit: cover;
  width: 250px;
  height: 250px;
  border: 1px solid #000000;
}
.holdmypaw button {
  color: #fff;
}
.holdmypaw a {
  font-size: 14px;
}
.holdmypaw .link a {
  text-decoration: underline;
  font-size: 18px;
  color: #000000 !important;
}
/* HOLDMYPAW ENDS*/
.iyzicoFooterLogo img {
  width: 300px;
}

.Header {
  position: fixed;
  left: 0;
  top: 0;
  max-height: 100px;
  width: 100vw;
  max-width: 100%;
  display: grid;
  grid-template-areas: "logo nav";
  background-color: #2b2f38;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.2);
  z-index: 2;
}

.Logo {
  grid-area: logo;
  height: 100px;
  filter: brightness(0) invert();
}

.Nav {
  display: grid;
  grid-area: nav;
  grid-template-columns: repeat(10, auto);
  align-items: center;
  justify-items: center;
}
.Nav a {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  transition: 0.5s;
  text-decoration: none;
}
.Nav a:hover {
  transform: scale(1.1);
}

.Burger {
  display: none;
  grid-area: burger;
  margin: 10px 20px 0 0;
  padding: 0;
  justify-self: end;
  font-size: 40px;
  border: none;
  background: none;
  outline: none;
  transition: 0.1s;
}
.Burger:active {
  transform: scale(1.2);
}

@media (max-width: 700px) {
  .Header {
    grid-template-areas: "logo burger" "nav nav";
  }
  .Nav {
    grid-template-rows: repeat(8, auto);
    grid-template-columns: none;
    grid-row-gap: 10px;
    padding: 0 0 10px;
    background: rgba(40, 44, 47, 0.95);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .Burger {
    display: inline;
  }
}

.NavAnimation-enter {
  opacity: 0;
  transform: scale(0.5);
}
.NavAnimation-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 350ms, transform 350ms;
}
.NavAnimation-exit {
  opacity: 1;
}
.NavAnimation-exit-active {
  opacity: 0;
  transform: scale(0.5);
  transition: opacity 350ms, transform 350ms;
}

.Header select {
  height: 30px;
}
